import React, { useEffect, useState, useRef } from "react";
import styles from "./card.module.scss";
import { useUser } from "../../state/UserContext";
import {
  robo,
  redHeart,
  grayHeart,
  sound_icon,
  sound_off_icon,
  logo,
} from "../../assets/images";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

const Card = (props) => {
  const {
    openVideoDisplay,
    index,
    productName,
    videoPreview,
    productImage,
    videoCapture,
    textPreview,
    productId,
    videoId,
    isMuted,
    toggleMute,
    lastVideoElementRef,
  } = props;

  const [likes, setLikes] = useState([]);
  const { plan, dateOfPost, productOwner } = textPreview;
  const { userData } = useUser();
  const video = useRef(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [duration, setDuration] = useState(null);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/posts/${videoId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const addUpVote = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/add-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      setLikes(response.data.likes);
    } catch (error) {}
  };

  const removeUpVote = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/remove-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      setLikes(response.data.likes);
    } catch (error) {}
  };

  async function handleLikeButtonClick() {
    if (likes?.includes(userData?._id)) {
      await removeUpVote();
    } else {
      await addUpVote();
    }
  }

  const handleCardClick = () => {
    if (!userData) {
      navigate("/login");
    }
    return;
  };

  const handleHeartClick = (e) => {
    e.stopPropagation();
    handleLikeButtonClick();
  };

  const formatDistanceDay = (date) => {
    try {
      const oneDay = 1000 * 3600 * 24;
      let parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        parsedDate = new Date(date.replace(/-/g, "/"));
      }
      const distance = Date.now() - parsedDate.getTime();
      if (distance < oneDay && distance > 0) {
        return "today";
      }
      let time = formatDistanceToNow(parsedDate, { addSuffix: true });
      let formattedDistance = time.replace(/in |about /g, "");
      return formattedDistance;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    async function getUpvotes() {
      const response = await fetchPost();
      if (response) setLikes(response.likes);
    }
    getUpvotes();
  }, []);

  useEffect(() => {
    const handleTouchStart = () => {
      if (video.current && video.current.paused) {
        video.current.play().catch((error) => {
          console.error("User interaction required to play video", error);
        });
      }
    };

    const handleTouchEnd = () => {
      if (video.current && !video.current.paused) {
        video.current.pause();
      }
    };

    if (video.current) {
      video.current.addEventListener("touchstart", handleTouchStart);
      video.current.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (video.current) {
        video.current.removeEventListener("touchstart", handleTouchStart);
        video.current.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (video.current) {
      video.current.currentTime = 0;
      video.current.play().catch((error) => {
        console.error("Video play error:", error);
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (video.current) {
      video.current.pause();
    }
  };

  const handleLoadedMetadata = () => {
    if (video.current) {
      setDuration(video.current.duration);
    }
  };

  return (
    <div className={styles.card} ref={lastVideoElementRef}>
      <div
        className={styles["media-container"]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && (
          <div
            id="mute"
            style={{
              position: "absolute",
              right: "10px",
              bottom: "10px",
              zIndex: "1000000",
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => toggleMute()}
          >
            <img
              src={isMuted ? sound_off_icon : sound_icon}
              alt="sound"
              id="mute-icon"
              style={{
                height: "25px",
                width: "25px",
                minHeight: "25px",
                minWidth: "25px",
                maxHeight: "25px",
                maxWidth: "25px",
                cursor: "pointer",
              }}
            />
          </div>
        )}

        <img
          style={{ display: !isHovered ? "block" : "none", maxHeight: 250 }}
          className="imgVideo"
          src={videoCapture}
          loading="lazy"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = logo;
          }}
          alt="Loading"
        />

        <video
          style={{ display: isHovered ? "block" : "none" }}
          preload="none"
          alt="Card"
          className={styles["post-video"]}
          muted={isMuted}
          // controls={isHovered}
          ref={video}
          onClick={() => openVideoDisplay(index)}
          onLoadedData={() => {}}
          onLoadedMetadata={handleLoadedMetadata}
          onError={(e) => console.error("Video load error:", e)}
        >
          <source src={videoPreview} type="video/mp4" />
        </video>

        {isHovered && duration && (
          <div
            className={styles["video-duration"]}
            style={{
              position: "absolute",
              left: "10px",
              bottom: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              color: "#fff",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {Math.floor(duration / 60)}:
            {("0" + Math.floor(duration % 60)).slice(-2)}
          </div>
        )}
      </div>
      <div className={styles["card-content"]}>
        <div className={styles["product-image"]}>
          {productImage ? (
            <div>
              <img
                src={
                  productImage.includes("upload")
                    ? `${process.env.REACT_APP_API_URL}/${productImage}`
                    : productImage
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = robo;
                }}
                alt="product"
                style={{ objectFit: "cover" }}
              />
            </div>
          ) : (
            <img src={robo} alt="default" style={{ objectFit: "cover" }} />
          )}
        </div>
        <div className={styles["card-details"]} onClick={handleCardClick}>
          <div className={styles["title-button-wrap"]}>
            <Link to={`/product-details/${productId}`} key={index}>
              <h2 className={styles["card-title"]}>{productName}</h2>
            </Link>

            <div className={styles["like-button"]} onClick={handleHeartClick}>
              <img
                src={likes?.includes(userData?._id) ? redHeart : grayHeart}
                alt="like button"
                className={styles["heart"]}
              />
              <span>{likes?.length}</span>
            </div>
          </div>
        </div>
      </div>
      <p className={styles["author"]}>
        <Link to={`/product-details/${productId}`} key={index}>
          <span>{plan}</span>
        </Link>
        {formatDistanceDay(dateOfPost)} By {productOwner}
      </p>
    </div>
  );
};

export default Card;
