import "./App.css";
import LoginPage from "./pages/auth/login-page/LoginPage";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from "./pages/dashboard/profile-settings/Profile";
import Layout from "./pages/Layout";
import SignupPage from "./pages/auth/signup-page/SignupPage";
import Board from "./pages/dashboard/board/UserBoard";
import Page404 from "./pages/dashboard/page-404/Page404";
import { UserProvider } from "./state/UserContext";
import ListProduct from "./pages/dashboard/products-list/ListProduct";
import VideoPage from "./pages/dashboard/video-page/VideoPage";
import MySpace from "./pages/dashboard/my-space/MySpace";
import ProductDetails from "./pages/dashboard/product-details/ProductDetails";
import VideoDetails from "./pages/dashboard/video-details/VideoDetails";
import UploadVideo from "./pages/dashboard/upload-video/UploadVideo";
import Matchmaking from "./pages/dashboard/matchmaking/Matchmaking";
import { VideoProvider } from "./state/VideoContext";
import { CameraProvider } from "./state/CameraContext";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import PrivateRoute from "./state/PrivateRoute";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App h-screen">
      <HelmetProvider>
        <BrowserRouter>
          <UserProvider>
            <VideoProvider>
              <Routes>
                <Route path="/" element={<Layout page={<Board />} />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                  path="/products-store"
                  element={<Layout page={<ListProduct />} />}
                />
                <Route
                  path="/my-space"
                  element={
                    <PrivateRoute>
                      <Layout page={<MySpace />} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/product-details/:id"
                  element={<Layout page={<ProductDetails />} />}
                />
                <Route
                  path="/video-details/:id"
                  element={<Layout page={<VideoDetails />} />}
                />
                <Route
                  path="/settings/profile"
                  element={
                    <PrivateRoute>
                      <Layout page={<Profile />} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/video"
                  element={
                    <PrivateRoute>
                      <Layout page={<VideoPage />} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/upload-video"
                  element={
                    <PrivateRoute>
                      <Layout page={<UploadVideo />} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/matchmaking-demo"
                  element={
                    <PrivateRoute>
                      <Layout page={<Matchmaking />} />
                    </PrivateRoute>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Not Found Page */}
                <Route path="*" element={<Page404 />} />

                {/* Not used routes */}
                {/* <Route path="/" element={<LandingPage />} /> */}
                {/* <Route path="/" element={<HomePageV2 />} /> */}
                {/* <Route path="/record-exemple" element={<RecordPageExample />} /> */}
                {/* <Route path="/board" element={<Layout page={<Board />} />} /> */}
                {/* <Route path="/analytics" element={<Layout page={<Analytics />} />} /> */}
                {/* <Route path="/reports" element={<Layout page={<Reports />} />} />*/}
                {/* <Route path="/action-log" element={<Layout page={<UserActionLog />} />} /> */}
                {/* <Route path="/saved-posts" element={<Layout page={<SavedPosts />} />} /> */}
                {/* <Route path="/saved-products" element={<Layout page={<SavedProducts />} />} /> */}
                {/* <Route path="/schedule" element={<Layout page={<Schedule />} />} /> */}
                {/* <Route path="/wishlist" element={<Layout page={<Wishlist />} />} /> */}
                {/* <Route path="/switch-to-businessowner" element={<Layout page={<SwitchToBusinessOwner />} />} /> */}
                {/* <Route path="/camera" element={<CameraComponent />} /> */}
                {/* <Route path="/settings/team" element={<Layout page={<Team />} />} /> */}
                {/* <Route path="/settings/edit-profile" element={<Layout page={<EditProfile />} />} /> */}
                {/* <Route path="/create-product" element={<Layout page={<CreateProduct />} />} /> */}
              </Routes>
            </VideoProvider>
          </UserProvider>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
