import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useUser, Context } from "../../../state/UserContext";
import axios from "axios";
import style from "./listProducts.module.scss";
import { NoData } from "../../../assets/images";
import ProductCard from "./ProductCard";
import { CircularProgress } from "@mui/material";
import LoginPopUp from "../../auth/login-page/LoginPopUp";
import { Helmet } from "react-helmet-async";

const ListProduct = ({ searchQuery }) => {
  const { userData } = useUser();
  const { products, setProducts } = useContext(Context);
  const { displayProducts, setDisplayProducts } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [myProductsRatings, setMyProductsRatings] = useState([]);
  const [page, setPage] = useState(1);
  const observer = useRef();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const PRODUCTS_PER_PAGE = 12;

  const LoadingIndicator = () => (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <CircularProgress />
    </div>
  );

  const getAllProducts = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
      const response = await res.json();

      setProducts(response);
      setDisplayProducts(response.slice(0, PRODUCTS_PER_PAGE));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  }, [setProducts]);

  const loadMoreProducts = useCallback(() => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    const start = displayProducts.length; // Start from the current length
    const end = start + PRODUCTS_PER_PAGE;

    if (start < products.length) {
      setDisplayProducts((prevProducts) => [
        ...prevProducts,
        ...products.slice(start, end), // Append next products
      ]);
      setHasMore(end < products.length); // Check if there are more products
    } else {
      setHasMore(false); // No more products to load
    }

    setIsLoadingMore(false);
  }, [products, hasMore, isLoadingMore, displayProducts.length]);

  const lastProductElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreProducts();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore, loadMoreProducts]
  );

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  return (
    <>
      <Helmet>
        <title>Recolyse | AI-Enhanced Startup Product Store.</title>
        <meta
          name="description"
          content="Browse our AI-based selection of startup products. Recolyse connects you with the latest in tech and AI solutions just for your needs."
        />
      </Helmet>
      <div>
        <div className={style["container"]}>
          <div className={style["header"]}>Products Store</div>
          {isLoading ? (
            <LoadingIndicator />
          ) : displayProducts.length > 0 ? (
            <div className={style.products}>
              {displayProducts.map((product, index) => {
                if (displayProducts.length === index + 1) {
                  // Attach the observer to the last product
                  return (
                    <div ref={lastProductElementRef} key={product._id}>
                      <ProductCard
                        product={product}
                        myProductsRatings={myProductsRatings}
                        userData={userData}
                        setOpenLoginPopUp={setOpenLoginPopUp}
                        isMuted={isMuted}
                        toggleMute={() => setIsMuted(!isMuted)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ProductCard
                      key={product._id}
                      product={product}
                      myProductsRatings={myProductsRatings}
                      userData={userData}
                      setOpenLoginPopUp={setOpenLoginPopUp}
                      isMuted={isMuted}
                      toggleMute={() => setIsMuted(!isMuted)}
                    />
                  );
                }
              })}
            </div>
          ) : (
            <div className={style["no-data"]}>
              <img src={NoData} alt="No Products Found" />
              <p>No Products Found</p>
            </div>
          )}
        </div>
        {/* {isLoadingMore && <LoadingIndicator />} */}
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => setOpenLoginPopUp(false)}
        />
      </div>
    </>
  );
};

export default ListProduct;
