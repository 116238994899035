import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../state/UserContext";

const PrivateRoute = ({ children }) => {
  const { userData } = useUser();

  // Check if user is authenticated
  return userData ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
