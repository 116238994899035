import React, { useEffect, useState } from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import { typo } from "../../../style";
import moment from "moment";
import { useUser } from "../../../state/UserContext";

const Profile = () => {
  const [selectedComponent, setSelectedComponent] = useState("editProfile");
  const { userData } = useUser();
  const [imageError, setImageError] = useState(false);

  useEffect(() => {}, [userData]);

  const handleItemClick = (component) => {
    setSelectedComponent(component);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="mb-5 ">
      {/* First Part */}
      <section className="flex justify-between items-center  mx-8">
        <h1 className={typo.pages_header + " mt-5"}> Profile Settings</h1>
      </section>

      {/* Second Part */}
      <section className="mx-6 mt-4 lg:flex lg:space-x-8">
        <div className="content max-h-[500px] lg:w-1/4 mb-6 p-4">
          <div className="flex gap-3 items-center">
            {userData?.picture.length > 0 && !imageError ? (
              <img
                alt="profile"
                name="picturePath"
                src={
                  userData?.picture.includes("upload")
                    ? `${process.env.REACT_APP_API_URL}/${userData?.picture}`
                    : userData?.picture
                }
                className="w-[67px] h-[67px] rounded-full object-cover mb-2 "
                onError={handleImageError}
              />
            ) : (
              <div className="profile-avatar">
                {userData?.firstName.slice(0, 1).toUpperCase()}
                {userData?.lastName.slice(0, 1).toUpperCase()}
              </div>
            )}

            <div>
              <p>
                Hi, {userData?.firstName} {userData?.lastName}
              </p>
              <p>Member since {moment(userData?.created_at).year()}</p>
            </div>
          </div>
          <div className="menu mt-8">
            <ul className="flex flex-col gap-[5px] cursor-pointer">
              <li
                className={
                  "p-4 " +
                  (selectedComponent === "editProfile"
                    ? "bg-[#FCEBE6] rounded-[13px] "
                    : "")
                }
                onClick={() => handleItemClick("editProfile")}
              >
                Edit Profile
              </li>
              <li
                className={
                  "p-4 " +
                  (selectedComponent === "changePassword"
                    ? "bg-[#FCEBE6] rounded-[8px]"
                    : "")
                }
                onClick={() => handleItemClick("changePassword")}
              >
                Change password
              </li>
            </ul>
          </div>
        </div>

        <div className="content lg:w-3/4 ">
          {selectedComponent === "editProfile" && <EditProfile />}
          {selectedComponent === "changePassword" && <ChangePassword />}
        </div>
      </section>
    </div>
  );
};

export default Profile;
