import React, { useState, useEffect } from "react";
import { avatar, msg } from "../../assets/images";
import axios from "axios";
import Moment from "moment";
import { useUser } from "../../state/UserContext";
import style from "./comment.module.scss";
import { BsSend } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  likeComment,
  dislikeComment,
  likeCommentActive,
  dislikeCommentActive,
} from "../../assets/images/index";
export default function PostComments({ videoId, productId }) {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const { userData } = useUser();
  const styles = {
    // fontSize: "20px",
    // fontWeight: "medium",
    width: "30px",
    height: "30px",
    borderRadius: "50px",
    color: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#fb8365",
  };
  const handleLikeAction = async (index) => {
    const comment = comments[index];
    const isLiked = comment.likes.users.includes(userData._id);
    let updatedComment;
    if (isLiked) {
      updatedComment = await removeLikes(index);
    } else {
      if (comment.dislikes.users.includes(userData._id))
        await removeDislikes(index);
      updatedComment = await addLikes(index);
    }

    if (updatedComment) {
      const updatedComments = [...comments];
      updatedComments[index] = updatedComment;
      setComments(updatedComments);
    }
  };

  const handleDisLikeAction = async (index) => {
    const comment = comments[index];
    const isDisliked = comment.dislikes.users.includes(userData._id);
    let updatedComment;
    if (isDisliked) {
      updatedComment = await removeDislikes(index);
    } else {
      if (comment.likes.users.includes(userData._id)) await removeLikes(index);
      updatedComment = await addDislikes(index);
    }

    if (updatedComment) {
      const updatedComments = [...comments];
      updatedComments[index] = updatedComment;
      setComments(updatedComments);
    }
  };

  const addLikes = async (index) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/comments/add-like/${comments[index]._id}/${userData._id}`
      );

      return response.data;
    } catch (error) {}
  };
  const removeLikes = async (index) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/comments/remove-like/${comments[index]._id}/${userData._id}`
      );

      return response.data;
    } catch (error) {}
  };
  const addDislikes = async (index) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/comments/add-dislike/${comments[index]._id}/${userData._id}`
      );

      return response.data;
    } catch (error) {}
  };
  const removeDislikes = async (index) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/comments/remove-dislike/${comments[index]._id}/${userData._id}`
      );

      return response.data;
    } catch (error) {}
  };
  const deleteComment = async (index) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/comments/${userData._id}/${comments[index]._id}/${videoId}`
    );

    const updatedComments = [...comments];
    updatedComments.splice(index, 1);
    setComments(updatedComments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = userData._id;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/comments/add-comment-to-post/${videoId}`,
        {
          userId: userId,
          text: comment,
        }
      );

      setComment("");
      setComments([...comments, res.data]);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/comments/get-post-comments`,
        { postId: videoId }
      );
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <div className={style["comments"]}>
      <div className={style["input-container"]}>
        <div className={style["avatar"]}>
          {userData && userData.picture.includes("uploads") ? (
            <img
              alt="profile-picture"
              name="picture"
              src={`${process.env.REACT_APP_API_URL}/${userData?.picture}`}
              style={{
                width: "52px",
                height: "52px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              className={style["avatar"]}
              style={{ backgroundColor: "#fb8365" }}
            >
              {userData.firstName.slice(0, 1).toUpperCase()}
              {userData.lastName.slice(0, 1).toUpperCase()}
            </div>
          )}
        </div>
        <input
          placeholder="Add a comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></input>
        <div className={style["send-icon"]}>
          <BsSend style={{ size: "24px" }} onClick={(e) => handleSubmit(e)} />
        </div>
      </div>
      <div className={style["comments-container"]}>
        {comments.map((comment, index) => (
          <div key={index} className={style["comment-box"]}>
            <div className={style["avatar"]}>
              {comment?.user?.picture != null &&
              comment?.user?.picture != "" ? (
                <img
                  alt="profile-picture"
                  name="picture"
                  src={
                    comment?.user?.picture.includes("uploads")
                      ? `${process.env.REACT_APP_API_URL}/${comment?.user?.picture}`
                      : comment?.user?.picture
                  }
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <div style={styles}>
                  {comment?.user?.firstName?.slice(0, 1).toUpperCase()}
                  {comment?.user?.lastName?.slice(0, 1).toUpperCase()}
                </div>
              )}{" "}
            </div>
            <div className={style["comment-content"]}>
              <div className={style["comment"]}>
                <div className={style["comment-info"]}>
                  <p className={style["publisher"]}>
                    {comment.user._id === userData._id
                      ? "Me"
                      : `${comment.user.firstName || ""} ${
                          comment.user.lastName || ""
                        }`}
                  </p>
                  <div className={style["date"]}>
                    {Moment(comment.updated_at).format("MMM d, yyyy hh:mm")}
                  </div>
                </div>
                {comment.user._id === userData._id && (
                  <div onClick={() => deleteComment(index)}>
                    <RiDeleteBin6Line
                      style={{ size: "12px", cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
              <div className={style["comment-text"]}>{comment.text}</div>
              <div className={style["comment-actions"]}>
                <div className={style["like-comment"]}>
                  <img
                    src={
                      comment.likes.users.includes(userData._id)
                        ? likeCommentActive
                        : likeComment
                    }
                    alt="like-comment"
                    onClick={() => handleLikeAction(index)}
                  ></img>

                  <span className={style["text"]}>
                    {comment.likes.nb_likes}
                  </span>
                </div>
                <div className={style["dislike-comment"]}>
                  <img
                    src={dislikeComment}
                    alt="dislike-comment"
                    onClick={() => handleDisLikeAction(index)}
                  ></img>

                  <span className={style["text"]}>
                    0{/* {comment.likes.nb_likes} */}
                  </span>
                  {/**comment.dislikes.users.includes(userData._id)
                        ? dislikeCommentActive
                        :  */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
