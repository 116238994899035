import React, { useEffect, useState } from "react";
import Card from "../card/Card.jsx";
import style from "./cardGrid.module.scss";
import { useNavigate } from "react-router-dom";
import LoginPopUp from "../../pages/auth/login-page/LoginPopUp.jsx";
import { useUser } from "../../state/UserContext.jsx";

const CardGrid = (props) => {
  const { videosData, requireLogin, lastVideoElementRef } = props;
  const { userData } = useUser();
  const navigate = useNavigate();
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const handleCardClick = (videoId, index) => {
    console.log("this is the videosData", videosData);
    if (!userData) {
      setOpenLoginPopUp(true);
      return;
    } else {
      navigate(`/video-details/${videoId}`);
    }
  };

  return (
    <div className={style["grid"]}>
      {videosData.map((data, index) => {
        if (videosData.length === index + 1) {
          return (
            <Card
              key={index}
              productName={data.product.name}
              videoPreview={data.videoURL}
              productImage={data.product.image}
              videoCapture={data.capture}
              textPreview={{
                plan: data.category,
                dateOfPost: data.createdAt,
                productOwner:
                  data.product.business_owner.firstName +
                  " " +
                  data.product.business_owner.lastName,
              }}
              productId={data.product._id}
              videoId={data._id}
              openVideoDisplay={() => handleCardClick(data._id)}
              index={index}
              isMuted={isMuted}
              toggleMute={() => setIsMuted(!isMuted)}
              lastVideoElementRef={lastVideoElementRef}
            ></Card>
          );
        } else {
          return (
            <Card
              key={data._id}
              productName={data.product.name}
              videoPreview={data.videoURL}
              productImage={data.product.image}
              videoCapture={data.capture}
              textPreview={{
                plan: data.category,
                dateOfPost: data.createdAt,
                productOwner: `${data.product.business_owner.firstName} ${data.product.business_owner.lastName}`,
              }}
              productId={data.product._id}
              videoId={data._id}
              openVideoDisplay={() => handleCardClick(data._id)}
              index={index}
              isMuted={isMuted}
              toggleMute={() => setIsMuted(!isMuted)}
            />
          );
        }
      })}
      <LoginPopUp
        open={openLoginPopUp}
        handleClose={() => setOpenLoginPopUp(false)}
      />
    </div>
  );
};

export default CardGrid;
// import React, { useEffect, useState } from "react";
// import Card from "../card/Card.jsx";
// import style from "./cardGrid.module.scss";
// import { useNavigate } from "react-router-dom";
// import LoginPopUp from "../../pages/auth/login-page/LoginPopUp.jsx";
// import { useUser } from "../../state/UserContext.jsx";

// const CardGrid = (props) => {
//   const { videosData } = props;
//   const { userData } = useUser();
//   const navigate = useNavigate();
//   const [openLoginPopUp, setOpenLoginPopUp] = useState(false);

//   const handleCardClick = (videoId) => {
//     console.log("this is the videosData", videosData);
//     if (!userData) {
//       setOpenLoginPopUp(true);
//       return;
//     } else {
//       navigate(`/video-details/${videoId}`);
//     }
//   };

//   useEffect(() => {
//     console.log("this is the videosData", videosData);
//   }, [videosData]);

//   return (
//     <div className={style["grid"]}>
//       {videosData.map((data) => (
//         <Card
//           key={data._id} // Use a unique identifier as the key
//           productName={data.product.name}
//           videoPreview={data.videoURL}
//           productImage={data.product.image}
//           videoCapture={data.capture}
//           textPreview={{
//             plan: data.category,
//             dateOfPost: data.createdAt,
//             productOwner: `${data.product.business_owner.firstName} ${data.product.business_owner.lastName}`,
//           }}
//           productId={data.product._id}
//           videoId={data._id}
//           openVideoDisplay={() => handleCardClick(data._id)}
//         />
//       ))}
//       <LoginPopUp open={openLoginPopUp} handleClose={() => setOpenLoginPopUp(false)} />
//     </div>
//   );
// };

// export default CardGrid;
