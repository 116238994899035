import React, { useContext, useEffect } from "react";
import style from "./productPopUp.module.scss";
import { xIcon } from "../../assets/images/index";
import { Context, useUser } from "../../state/UserContext";
import { noProductsIcon } from "../../assets/images/index";
import { robo } from "../../assets/images";
import { useNavigate } from "react-router";
import { Box, CircularProgress, Modal } from "@mui/material";
import CreateProductModal from "../create-new-product/CreateProductModal";
import { useState } from "react";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "820px",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: "24px",
  boxShadow: "0px 4px 100px 0px rgba(255, 255, 255, 0.24)",
  pt: 1,
  px: 2,
  pb: 1,
};

function ProductsPopUp({ nextActive, loading }) {
  const { openProductPopUp, setOpenProductPopUp } = React.useContext(Context);
  const { openChooseProduct, setOpenChooseProduct } = React.useContext(Context);

  const { openRecordPopUp, setOpenRecordPopUp } = React.useContext(Context);
  const [searchQuery, setSearchQuery] = React.useState("");

  const { userData } = useUser();
  const { productList, setProductList } = useContext(Context);
  const [products, setProducts] = useState(productList);
  const [isLoadingProduct, setIsLoadingProducts] = useState(false);
  const [filteredProducts, setFilteredProducts] = React.useState(
    Array.isArray(products) ? products : []
  );
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const navigate = useNavigate();
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered =
      query === ""
        ? products
        : products?.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
          );
    setFilteredProducts(filtered);
  };

  const handleSelectProduct = (product) => {
    if (selectedProduct === product) setSelectedProduct(null);
    else {
      setSelectedProduct(product);
      localStorage.setItem("selectedProduct", JSON.stringify(product));
    }
  };

  const handleCloseProductPopup = () => {
    setOpenProductPopUp(false);
    setOpenChooseProduct(false);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNext = async () => {
    try {
      if (openProductPopUp) {
        setOpenProductPopUp(false);
        setOpenRecordPopUp(true);
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } else if (openChooseProduct) {
        setOpenChooseProduct(false);
        navigate("/upload-video");
      }
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const getBusinessOwenerProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
      );
      const response = await res.json();
      if (Array.isArray(response)) {
        setProductList(response);
        setProducts(response);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoadingProducts(false);
    }
  };
  useEffect(() => {
    if (userData != null) {
      getBusinessOwenerProducts();
    }
  }, [userData]);

  useEffect(() => {
    if (productList) {
      setProducts(productList);
      setFilteredProducts(productList);
    }
  }, [productList]);

  return (
    <Modal
      className={style["products-pop"]}
      open={openProductPopUp || openChooseProduct}
      onClose={handleCloseProductPopup}
      id="modall"
    >
      <Box className={style["box-content"]} sx={{ ...styleModal }}>
        <div className={style["title-box"]}>
          <span>Choose Your product</span>
          <img
            src={xIcon}
            alt="close"
            onClick={() => handleCloseProductPopup()}
          ></img>
        </div>

        {isLoadingProduct ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <CircularProgress size={34} />
          </div>
        ) : products.length == 0 ? (
          <div className={style["no-products-box"]}>
            <div className={style["no-result-box"]}>
              <img src={noProductsIcon} alt="no products"></img>
            </div>
            <span>No Product Yet</span>
            <button onClick={handleOpenModal}>Let’s Create Product 😉</button>
            <CreateProductModal open={modalOpen} onClose={handleCloseModal} />
          </div>
        ) : (
          <>
            <div className={style["search-box"]}>
              <input
                placeholder="Search Product"
                value={searchQuery}
                onChange={handleSearch}
              ></input>
            </div>

            {products.length !== 0 && filteredProducts?.length == 0 ? (
              <div className={style["no-result-box"]}>
                <img src={noProductsIcon} alt="no products"></img>
              </div>
            ) : (
              <div className={style["box"]}>
                {Array.isArray(filteredProducts) &&
                  filteredProducts?.map((product, index) => (
                    <div key={index} className={style["product-box"]}>
                      <div
                        className={`${style["product-content"]} ${
                          selectedProduct === product
                            ? style["selected"]
                            : style["product"]
                        }`}
                        onClick={() => handleSelectProduct(product)}
                      >
                        {product?.image ? (
                          <img
                            src={
                              product.image.includes("upload")
                                ? `${process.env.REACT_APP_API_URL}/${product.image}`
                                : product.image
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = robo;
                            }}
                            alt="product"
                          />
                        ) : (
                          <img src={robo} alt="default"></img>
                        )}
                        <span
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            fontWeight: "600",
                            marginTop: "5px",
                          }}
                          className={`${style["product-name"]}`}
                        >
                          {product.name}
                        </span>
                        <span
                          className={`${style["product-desc"]}`}
                          style={{ fontSize: "12px", color: "#848484" }}
                        >
                          {product.description.split(",")[0]}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            <div className={style["buttons"]}>
              <button
                className={style["cancel-button"]}
                onClick={() => setOpenProductPopUp(false)}
              >
                Cancel
              </button>
              <button
                disabled={selectedProduct == null}
                className={
                  nextActive
                    ? style["next-button-active"]
                    : style["next-button"]
                }
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ProductsPopUp;
